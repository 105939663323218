
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ocpp-fileserver-ui-primary: mat.define-palette(mat.$indigo-palette);
$ocpp-fileserver-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ocpp-fileserver-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ocpp-fileserver-ui-theme: mat.define-light-theme((
  color: (
    primary: $ocpp-fileserver-ui-primary,
    accent: $ocpp-fileserver-ui-accent,
    warn: $ocpp-fileserver-ui-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ocpp-fileserver-ui-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Montserrat;
    src: url(assets/fonts/Montserrat-VariableFont_wght.ttf);
}

* {
    font-family: Montserrat;
    font-size: 16px;
}

h1 {
    font-size: min(10vw, 48px);
    font-weight: bold;
    margin-bottom: 5vh;
    height: 25vw;
    max-height: 120px;
}

.form {
    margin: 20px 0  0 min(50px, 5vw);
}

@media only screen and (min-width: 779px) {
    h1 {
        max-height: 80px;
    }
}

input[type=text] {
    color: black;
}

label {
    display: block;
    padding-left: 16px;
    padding-bottom: 3px;
    font-weight: bold;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
